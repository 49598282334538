
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { Action } from 'vuex-class'

@Component
export default class Login extends Vue {
  @Validate({ required }) email = ''
  //@Validate({ required, email }) email = "";
  @Validate({ required, minLength: minLength(8) }) password = ''
  @Action setLogin!: Function

  public showPass = false

  protected async login() {
    let login = { password: this.password }

    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi
    const isEmail = regexExp.test(this.email)

    if (isEmail) {
      login.email = this.email
    } else {
      login.username = this.email
    }

    this.setLogin(login)
  }

  redirigirAlScanner() {
    // Redirige a la URL del escáner
    window.location.href = process.env.VUE_APP_SCANNER
  }

  get showPassword(): boolean {
    return this.showPass ? true : false
  }

  public clearForm(): void {
    this.$v.$reset()
    this.email = ''
    this.password = ''
  }

  get isDisabled(): boolean {
    return !this.email || !this.password || this.password.length < 8
      ? true
      : false
  }

  get emailErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.email.$dirty) return errors
    !this.$v.email.required && errors.push('Este campo es requerido')
    //!this.$v.email.email && errors.push("Debe ser un email válido");
    return errors
  }
  get passwordErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.password.$dirty) return errors
    !this.$v.password.required && errors.push('Este campo es requerido')
    !this.$v.password.minLength && errors.push('Mínimo 8 dígitos')
    return errors
  }
}
